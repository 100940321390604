.coming-soon-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
 
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    /* display: flex; */
    filter: brightness(0.5);  
  }
  
  .content {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    z-index: 1;
    font-family: Montserrat, sans-serif;
  }

  .company-logo {
    width: 180px; 
    height: auto;
    margin-bottom: 0px; 
  }
  
  h1 {
    font-size: 3rem;
    font-style: italic;
    margin-top:  1rem;
    margin-bottom: 1rem;
    color:#ffffff;
    font-family: Montserrat, sans-serif;
  }
  
  strong, h1 span {
    color: #ff3753;
  }

  p {
    font-size: 1.3rem;
    margin-bottom: 2rem;
    font-family: Montserrat, sans-serif;
  }
  
  .countdown {
    font-size: 1.3rem;
  }
  